import React, { Component } from 'react'
import { BoxEbook } from './style'

import IconeDownload from '../../images/download.svg'

class ComponentBoxEbook extends Component {
  render() {
    return (
      <BoxEbook href={this.props.link_download} target="_blank">
        <div className="img">
          <img src={this.props.img_ebook} alt={`Imagem Ebook ${this.props.titulo_ebook}`} />
        </div>
        <div className="info">
          <h3>{this.props.titulo_ebook}</h3>
          <p>{this.props.descricao_ebook}</p>
          <span>
            <img src={IconeDownload} alt="Icone download" /> Baixar agora!
          </span>
        </div>
      </BoxEbook>
    )
  }
}

export default ComponentBoxEbook

import React from 'react'
import NavigationContainers from 'components/navigation-containers'

import { useStaticQuery, graphql } from 'gatsby'

// Components Globals
import Header from 'components/header'
import Footer from 'components/footer/main'
import Demo from 'components/s-demo/main'
import SEO from 'components/seo'

import { BannerConteudo, SectionCounteudos } from '../../Style/conteudos'
import { Container, SeloTitle } from '../../Style/global'
import BoxEbook from 'components/box-ebook'

// Sass Global
import 'sass/main.scss'

const PageConteudos = () => {
  const images = useStaticQuery(graphql`
    query {
      allFile(
        filter: { sourceInstanceName: { eq: "images" }, extension: { regex: "/(jpg)|(png)|(jpeg)/" }, relativeDirectory: { eq: "ebook" } }
        sort: { fields: [base], order: DESC }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid(maxWidth: 248, quality: 92) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  let mappedImages = {}

  images.allFile.edges.map(image => {
    mappedImages[image.node.base] = image.node.childImageSharp.fluid.src
  })

  return (
    <div className="main">
      <SEO title="E-books" />

      <Header />

      <BannerConteudo>
        <Container className="cont">
          <SeloTitle>Conteúdos</SeloTitle>
          <h1>Conteúdos para descomplicar a gestão do seu negócio</h1>
          <NavigationContainers />
        </Container>
      </BannerConteudo>

      <SectionCounteudos>
        <Container>
          <div className="all-box">
            <BoxEbook
              link_download="https://conteudo.inovafarma.com.br/macetes_atualizacao_preco"
              img_ebook={mappedImages['img-ebook-preco.png']}
              titulo_ebook="10 Dicas para Atualizar o Preço dos Medicamentos"
              descricao_ebook="Conheça técnicas e macetes mais utilizados na hora de atualizar o preço dos medicamentos para manter uma boa margem de lucro!"
            />
            <BoxEbook
              link_download="http://conteudo.inovafarma.com.br/scripts-cobranca-farmacia"
              img_ebook={mappedImages['img-ebook-cobranca.png']}
              titulo_ebook="5 Roteiros de Cobrança para Clientes Inadimplentes "
              descricao_ebook="Se você trabalha ou já trabalhou com crediário próprio, sabe que fazer cobrança de dívida pode ser algo difícil. Mas existem técnicas que facilitam esse processo, confira!"
            />
            <BoxEbook
              link_download="https://conteudo.inovafarma.com.br/mtk-sensorial"
              img_ebook={mappedImages['img-ebook-sensorial.png']}
              titulo_ebook="Como Influenciar a Decisão de Compra dos Consumidores"
              descricao_ebook="Saiba com usar os 5 sentidos através do Marketing Sensorial para potencializar as vendas na sua farmácia!"
            />
            <BoxEbook
              link_download="https://conteudo.inovafarma.com.br/dicas_exposicao_produtos"
              img_ebook={mappedImages['img-ebook-exposicao.png']}
              titulo_ebook="Guia Completo: Como Fazer a Exposição Correta dos Produtos"
              descricao_ebook="Você sabia que o seu faturamento pode aumentar em cerca de 20% apenas alterando a exposição dos produtos nas prateleiras? Saiba como."
            />
            <BoxEbook
              link_download="https://conteudo.inovafarma.com.br/organizacao_farmacia"
              img_ebook={mappedImages['img-ebook-checklist.png']}
              titulo_ebook="Checklist para Organizar a Rotina da Farmácia"
              descricao_ebook="Baixe agora mesmo e comece a organizar sua farmácia de forma correta, para uma ter uma rotina de sucesso e crescimento de vendas!"
            />
            <BoxEbook
              link_download="https://conteudo.inovafarma.com.br/como-escolher-colaborador-certo"
              img_ebook={mappedImages['img-ebook-escolher-colaborador-correto.png']}
              titulo_ebook="Saiba Como Escolher o Colaborador CERTO para sua farmácia"
              descricao_ebook="Baixe agora mesmo e saiba como escolher o colaborador Certo para trabalhar na sua farmácia ou drogaria!"
            />
            <BoxEbook
              link_download="https://conteudo.inovafarma.com.br/ebook-roteiro-para-atendimento-top"
              img_ebook={mappedImages['img-ebook-atendimento-na-farmacia.png']}
              titulo_ebook="Passo a Passo para Fazer um Atendimento TOP na sua Farmácia"
              descricao_ebook="Baixe agora nosso eBook e veja as técnicas para você fazer um atendimento de excelência no balcão da sua farmácia!"
            />
            <BoxEbook
              link_download="https://conteudo.inovafarma.com.br/ebook-guia-rapido-do-sngpc"
              img_ebook={mappedImages['img-ebook-sngpc.png']}
              titulo_ebook="Guia Rápido do SNGPC para farmacêuticos"
              descricao_ebook="eBook com dicas para você evitar erros na hora de transmitir o arquivo da farmácia para o SNGPC. Baixe agora!"
            />
            <BoxEbook
              link_download="https://conteudo.inovafarma.com.br/ebook-controle-financeiro"
              img_ebook={mappedImages['img-ebook-gestao-financeira-para-farmacias.png']}
              titulo_ebook="Guia Completo: Gestão Financeira para Farmácias"
              descricao_ebook="Principais ferramentas e métodos que você aprende!"
            />
            <BoxEbook
              link_download="https://conteudo.inovafarma.com.br/ebook-guia-completo-vendas"
              img_ebook={mappedImages['img-ebook-como-aumentar-as-vendas-de-sua-farmacia.png']}
              titulo_ebook="Guia Completo: Como Aumentar as Vendas de sua Farmácia"
              descricao_ebook="Principais ferramentas e métodos que você aprende!"
            />
            <BoxEbook
              link_download="https://conteudo.inovafarma.com.br/ebook-caixa"
              img_ebook={mappedImages['img-ebook-controle-de-caixa-para-farmacia.png']}
              titulo_ebook="Guia Completo: Controle de Caixa para Farmácia"
              descricao_ebook="Principais ferramentas e métodos que você aprende!"
            />
          </div>
        </Container>
      </SectionCounteudos>

      <Demo />

      <Footer padding_maior={true} />
    </div>
  )
}

export default PageConteudos

import styled from 'styled-components'

export const BoxEbook = styled.a`
  display: block;
  max-width: 285px;
  &:hover {
    .info {
      span {
        transform: translateY(0px);
        opacity: 1;
        transition: all 0.4s;
      }
    }
  }
  .img {
    width: 248px;
    height: 221px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 52px;
    img {
      max-width: 100%;
    }
  }
  .info {
    h3 {
      font: normal bold 24px/26px 'DepotNew';
      color: #636466;
    }
    p {
      font-size: 15px;
      line-height: 24px;
      color: #94969b;
      margin: 28px 0px;
    }
    span {
      display: flex;
      align-items: center;
      font: normal 500 18px/26px 'DepotNew';
      color: #0095da;
      transform: translateY(10px);
      opacity: 0;
      transition: all 0.4s;
      img {
        margin-right: 11px;
      }
    }
  }
  @media (max-width: 1050px) {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .img {
      margin-bottom: 30px;
    }
    .info {
      h3 {
        text-align: center;
        max-width: 260px;
        margin: 0 auto;
      }
      p {
        text-align: center;
        margin-top: 10px;
        margin-bottom: 20px;
      }
      span {
        transform: translateY(0px);
        opacity: 1;
        justify-content: center;
      }
    }
  }
`
